<template>
  <div class="review-bg">
    <Navbar />
    <div class="review-body">
      <TopBar />

      <div class="filter-bar boxarea">
        <button @click="handleMove">{{ $t("reset_review_select") }}</button>
      </div>
      <section class="review-marketing">
        <div class="marketing-left">
          <div class="review-frame boxarea">
            <h2>{{ $t("select_review_frame") }}</h2>
            <div>
              <select v-model="reviewFont" @change="handleReviewfont">
                <option value disabled>font select</option>
                <option value="NOTO">Noto Sans KR</option>
                <option value="NANUM">NanumSquare</option>
              </select>
              <select v-model="reviewType" @change="handleReviewtype">
                <option value disabled>{{ $t("type_select") }}</option>
                <option value="square">{{ $t("rectangular_type") }}</option>
                <option value="bedge">{{ $t("badge_type") }}</option>
              </select>
            </div>
          </div>
          <div class="review-color boxarea">
            <div class="review-bgcolor">
              <h2>{{ $t("select_brand_color") }}</h2>
              <div>
                <sketch-picker v-model="colorObj"></sketch-picker>
              </div>
            </div>
            <div class="review-font">
              <h2>{{ $t("select_font_color") }}</h2>
              <input type="radio" id="font-black" name="fontcolor" value="#111" v-model="fontColor" />
              <label for="font-black">
                <b>
                  <span></span>
                  {{ $t("black") }}
                </b>
              </label>
              <input type="radio" id="font-white" name="fontcolor" value="#fff" v-model="fontColor" />
              <label for="font-white">
                <b>
                  <span class="white"></span>
                  {{ $t("white") }}
                </b>
              </label>
              <input type="radio" id="font-grey" name="fontcolor" value="#ccc" v-model="fontColor" />
              <label for="font-grey">
                <b>
                  <span class="grey"></span>
                  {{ $t("gray") }}
                </b>
              </label>
            </div>
          </div>
          <div class="review-share boxarea">
            <div class="review-sns">
              <h2>{{ $t("share") }}</h2>
              <div>
                <ShareNetwork network="Twitter" url="https://reviewmind.com/company" title="Say hi to ReviewMind">
                  <img src="@/assets/images/reviewmind/share-twitter.png" alt="SNS" />
                  <span>twitter</span>
                </ShareNetwork>

                <ShareNetwork network="Facebook" url="https://reviewmind.com/company" title="Say hi to ReviewMind">
                  <img src="@/assets/images/reviewmind/share-facebook.png" alt="SNS" />
                  <span>facebook</span>
                </ShareNetwork>
                <a @click="handleShare('naver')">
                  <img src="@/assets/images/reviewmind/share-naver.png" alt="SNS" />
                  <span>naver</span>
                </a>
                <a @click="handleShare('kakao')">
                  <img src="@/assets/images/reviewmind/share-kakao.png" alt="SNS" />
                  <span>kakao</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="marketing-right boxarea">
          <h2>
            {{ $t("review_marketing_preview") }}
            <a @click="print">{{ $t("save_image") }}</a>
          </h2>
          <div v-if="reviewData.length > 0" ref="print" class="review-preview" :style="{ background: colorObj.hex8 }">
            <div class="preview-summary">
              <div class="powerby">
                <img src="@/assets/images/reviewmind/powerby.png" alt />
              </div>
              <h2 :style="{ color: fontColor }">
                {{ reviewData[0].review_source }}
                {{ reviewData[0].productName }} {{ $t("preview_summary") }}
              </h2>
              <div class="reviewChart">
                <Bar-Chart title="리뷰 현황 요약" subTitle2="533" :fontColor="fontColor" :series="series"></Bar-Chart>
                <div>
                  <h3 :style="{ color: fontColor }">
                    {{ (totalRating / totalCount).toFixed(2) }}
                  </h3>
                  <span>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="far fa-star"></i>
                  </span>
                </div>
              </div>
            </div>
            <ul>
              <li v-for="(data, i) of reviewData" :key="i">
                <h2>
                  {{ data.rating }}
                  <i class="fas fa-star" style="margin: 1px" v-for="(item, j) in data.rating" :key="j"></i>
                </h2>
                <span v-if="data.review_source">via {{ data.review_source }}</span>
                <span>{{ moment(data.review_date).format("YYYY-MM-DD") }}</span>
                <p>
                  {{ data.review }}
                  <span>{{ data.reviewer_name }}</span>
                </p>
              </li>
            </ul>
            <div id="bottomSensor"></div>
          </div>
          <div v-else class="nodata">{{ $t("select_comment") }}</div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { eventBus } from "@/main";
import moment from "moment";
import { Sketch } from "vue-color";
import BarChart from "@/components/charts/BarChart";
import Navbar from "@/components/Navbar";
import TopBar from "@/components/dashboard/TopBar";
// import VueHtml2Canvas from "vue-html2canvas";
// import { uploadFile } from "@/api/index";
import { format } from "@/mixins/format";
import { fetchReviewScore } from "@/api/review";

export default {
  mixins: [format],
  components: {
    BarChart,
    "sketch-picker": Sketch,
    TopBar,
    Navbar,
  },

  computed: {
    ...mapState(["navVisible", "footerVisible", "reviewData"]),
  },
  data() {
    return {
      moment: moment,
      colorObj: {},
      since: "6", // default 기간 설정
      fontColor: "#ffffff",
      text: "",
      title: "1111",
      output: "",
      url_combine_tw: "",
      imageUrl: "",
      reviewList: [],
      rating: 0,
      totalCount: 0,
      totalRating: 0,
      series: [],
      reviewType: "square",
      reviewFont: "NOTO",
    };
  },
  created() {
    this.$store.dispatch("DASHBOARD_MENU", 6);
  },
  metaInfo() {
    return {
      meta: [
        // Twitter Card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: "Vue Social Cards Example" },
        {
          name: "twitter:description",
          content: "Vue sample site showing off Twitter and Facebook Cards.",
        },
        {
          name: "twitter:image",
          content: "https://ceasars.s3.ap-northeast-2.amazonaws.com/image-1595573488764.jpeg",
        },
        // Facebook OpenGraph
        { property: "og:title", content: "Vue Social Cards Example" },
        { property: "og:site_name", content: "Vue Example" },
        { property: "og:type", content: "website" },
        {
          property: "og:image",
          content: "https://ceasars.s3.ap-northeast-2.amazonaws.com/image-1595573488764.jpeg",
        },
        {
          property: "og:description",
          content: "Vue sample site showing off Twitter and Facebook Cards.",
        },
      ],
    };
  },
  mounted() {
    eventBus.$on("reviewData", (review) => {
      this.$nextTick(function() {
        this.reviewList = review;
      });
    });

    this.getReviewScore();
  },
  methods: {
    handleMove() {
      this.$router.push("/dashboard/reviewmind5");
    },
    handleReviewtype() {
      if (this.reviewType == "bedge") {
        document.querySelector(".review-preview").classList.add("bedge");
      } else {
        document.querySelector(".review-preview").classList.remove("bedge");
      }
    },
    handleReviewfont() {
      if (this.reviewFont == "NANUM") {
        document.querySelector(".review-preview").classList.add("nanum");
      } else {
        document.querySelector(".review-preview").classList.remove("nanum");
      }
    },
    setNavbar(type) {
      let data = {
        visible: type,
      };
      this.$store.dispatch("SET_NAVBAR", data);
      this.$store.dispatch("SET_FOOTER", data);
    },
    async print() {
      const el = this.$refs.print;

      this.output = await window.html2canvas(el);
      var dataUrl = this.output.toDataURL("image/jpeg", 0.9);
      var link = document.createElement("a");
      link.download = "my-image-name.jpeg";
      link.href = dataUrl;
      link.click();
    },
    // async sendFile() {
    //   let file = this.output;
    //   const formdata = new FormData();
    //   formdata.append("file", file);
    //   uploadFile(formdata).then((res) => {
    //     if (res.status === 200) {
    //       this.imageUrl = res.data.path;
    //     }
    //   });
    // },

    getReviewScore() {
      fetchReviewScore().then((res) => {
        if (res.status == 200) {
          this.totalCount = res.data.data.totalCount;
          this.rating = res.data.data.rating;
          let arr = [0, 0, 0, 0, 0];
          let ratingCount = arr.map((item, i) => {
            if (this.rating[i] && this.rating[i]._id) {
              if (this.rating[i]._id == 5 - i) {
                return this.rating[i].count;
              } else {
                return null;
              }
            } else {
              return null;
            }
            // if (item._id > 0) {
            //   return item.count;
            // }
          });
          let ratingSum = this.rating.map((item) => {
            if (item._id > 0) {
              return item.total;
            }
          });
          this.series = [
            {
              data: ratingCount,
            },
          ];
          this.totalRating = this.sumArray(ratingSum);
        } else {
          return alert(res.data.message);
        }
      });
    },
    sumArray(array) {
      let result = 0.0;

      for (let i = 0; i < array.length; i++) result += array[i];

      return result;
    },
    handleShare(type) {
      if (this.reviewData == "") {
        alert("고객 인사이트 메뉴에서 마케팅에 활용할 리뷰를 선택해 주세요.");
        this.$router.push("/dashboard/reviewmind5");
      }
      if (type == "twitter") {
        window.open(`https://twitter.com/intent/tweet?image=${this.ouput}`, "", "width=600,height=300,top=100,left=100,scrollbars=yes");
      } else if (type == "facebook") {
        let cUrl = "http://www.facebook.com/sharer/sharer.php?u=reviewmind.com/dashboard/reviewmind6";
        window.open(cUrl, "", "width=600,height=300,top=100,left=100,scrollbars=yes");
      } else if (type == "naver") {
        let url = encodeURI(encodeURIComponent("reviewmind.com"));
        let title = encodeURI(this.reviewData[0].productName);
        let shareURL = "https://share.naver.com/web/shareView?url=" + url + "&title=" + title;
        window.open(shareURL);
      } else if (type == "kakao") {
        window.Kakao.Link.sendDefault({
          objectType: "feed",
          content: {
            title: this.reviewData[0].productName,
            imageUrl: "",
            link: {
              webUrl: encodeURIComponent("reviewmind.com"),
              mobileWebUrl: encodeURIComponent("reviewmind.com"),
            },
          },
        });
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch("SET_ASIN", "");
    this.$store.dispatch("SET_URL", "");
    this.$store.dispatch("VIEW_TERM", "");
  },
};
</script>
