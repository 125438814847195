<template>
  <VueApexCharts ref="apexChart" type="bar" :options="chartOptions" :series="series" height="100%" width="100%"></VueApexCharts>
</template>
<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {},
    subTitle2: {},
    fontColor: {
      type: String,
    },
    series: {
      type: Array,
    },
  },

  watch: {
    fontColor: {
      handler() {
        this.$refs.apexChart.updateOptions({
          yaxis: {
            labels: {
              style: {
                colors: this.fontColor,
              },
            },
          },
        });
      },
    },
  },

  data() {
    return {
      // series: [
      //   {
      //     data: [100, 200, 1, 30, 20],
      //   },
      // ],
      chartOptions: {
        chart: {
          type: "bar",
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "50%",
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#000"],
        dataLabels: {
          offsetX: -3,
          style: {
            // colors: ["#000"],
            fontWeight: "normal",
          },
        },
        xaxis: {
          categories: ["5 Point", "4 Point", "3 Point", "2 Point", "1 Point"],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: this.fontColor,
            },
          },
        },
        fill: {
          colors: ["#0088cf"],
          type: "gradient", // gradient
          gradient: {
            type: "horizontal", // The gradient in the horizontal direction
            gradientToColors: ["#ed207d"], // The color at the end of the gradient
            opacityFrom: 1, // transparency
            opacityTo: 1,
            stops: [0, 120],
          },
        },

        tooltip: {
          enabled: false,
        },
        grid: {
          show: false,
        },
      },
    };
  },

  methods: {},
};
</script>
